<template>
  <section class="w-full">
    <b-card class="invoice-preview-card m-8">
      <div class="flex justify-between">
        <b-card class="border-0 float-right h-25">
          <h4 class="invoice-title border px-5 border-black py-2">
            <span class="text-primary"> شماره فاکتور : </span>
            {{ manulaData.invoice_number }}
          </h4>
          <br />
          <div v-if="manulaData.from_date">
            <div class="flex justify-around">
              <p class="font-weight-bold">از تاریخ:</p>
              <p class="">{{ manulaData.from_jalali_date }}</p>
            </div>

            <div class="flex justify-around">
              <p class="font-weight-bold">تا تاریخ:</p>
              <p class="">{{ manulaData.to_jalali_date }}</p>
            </div>
          </div>
          <div v-else class="flex justify-around">
            <p class="font-weight-bold">تاریخ:</p>
            <p v-if="manulaData.created_at" class="">
              {{ manulaData.created_at.split(" ")[0] }}
            </p>
          </div>
        </b-card>
        <div>
          <h2>صورتحساب فروش کالا یا خدمات</h2>
        </div>
        <div class="logo-wrapper">
          <img
            :src="require('@/assets/images/logo/logo-haio.png')"
            style="width:200px; height:100px"
            alt="logo"
          />
        </div>
      </div>
      <!-- Header -->
      <vs-divider color=""
        ><span class="px-4 py-1 font-size-sm font-weight-bold"
          >فروشنده</span
        ></vs-divider
      >
      <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column">
          <div class="divTable" style="border: 2px solid #000">
            <div class="divTableBody">
              <div class="divTableRow">
                <div class="divTableCell">
                  <p class="card-text">
                    <span class="font-weight-bold">نام شرکت:</span>
                    شرکت ویرا ابر آینده جهان
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">شماره اقتصادی :</span>
                    411656646994
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold">
                      شماره ثبت / شماره ملی :</span
                    >
                    14008780329
                  </p>
                </div>
              </div>
              <div class="divTableRow">
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="font-weight-bold"> آدرس :</span>
                    تهران پرند فاز ۲ بلوار شقایق -خ عطارد-مجتمع آریا-بلوک c-واحد
                    ۱۱۰
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="mx-1 font-weight-bold"> تلفن : </span>
                    <span dir="ltr" class=""> (۰۲۱) ۹۱۰۷۰۸۶۶</span>
                  </p>
                </div>
                <div class="divTableCell">
                  <p class="card-text mb-25">
                    <span class="mx-1 font-weight-bold"> کد پستی : </span>
                    <span dir="ltr" class=""> 3761341444</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>

      <!-- Spacer -->
      <vs-divider color=""
        ><span class="px-4 py-1 font-size-sm font-weight-bold"
          >خریدار</span
        ></vs-divider
      >

      <div class="p-4">
        <div v-if="!manulaData.is_legal" class="divTable" style="border: 2px solid #000">
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <p>
                  <span class="mx-1 font-weight-bold"> نام : </span>
                  <span dir="ltr" class=""> {{ manulaData.user.name }}</span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره ثبت/ شماره ملی :
                  </span>
                  <span dir="ltr" class="">
                    {{ manulaData.user.national_id }}</span
                  >
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text my-2">
                  <span class="my-2 font-weight-bold"> آدرس : </span>
                  <span dir="ltr" class="">{{ manulaData.user.address }}</span>
                </p>
              </div>
            </div>
            <div class="divTableRow">
              <div class="divTableCell">
                <p class="card-text my-2 mb-25">
                  <span class="mx-1 font-weight-bold"> کد پستی : </span>
                  <span dir="ltr" class="">{{
                    manulaData.user.postal_code
                  }}</span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره تلفن/نمابر :
                  </span>
                  <span dir="ltr" class=""> {{ manulaData.user.phone }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- company name -->
        <div v-else class="divTable my-4" style="border: 2px solid #000">
          <div class="divTableBody">
            <div class="divTableRow">
              <div class="divTableCell">
                <p>
                   <span class="mx-1 font-weight-bold"> نام شرکت : </span>
                  <span dir="ltr" class="">
                    {{ manulaData.user.company_name }}
                  </span>
                </p>
              </div>
              <div class="divTableCell">
                <p class="card-text mb-25">
                  <span class="mx-1 font-weight-bold">
                    شماره ثبت/ شماره ملی :
                  </span>
                  <span dir="ltr" class="">
                    {{ manulaData.user.national_id }}</span
                  >
                </p>
              </div>
              <div class="divTableCell">
                 <p v-show="manulaData.is_legal" class="card-text mb-25">
                  <span class="my-1 font-weight-bold"> شماره اقتصادی : </span>
                  <span dir="ltr" class="">
                    {{ manulaData.user.economic_code }}</span
                  >
                </p>
              </div>

            </div>
            <div class="divTableRow">
              <div class="divTableCell">
                              <p class="card-text my-2">
                <span class="my-2 font-weight-bold"> آدرس : </span>
                <span dir="ltr" class="">{{ manulaData.user.address }}</span>
              </p>
              </div>
              <div class="divTableCell">
                              <p class="card-text my-2 mb-25">
                <span class="mx-1 font-weight-bold"> کد پستی : </span>
                <span dir="ltr" class="">{{
                  manulaData.user.postal_code
                }}</span>
              </p>
              </div>
              <div class="divTableCell">

              <p class="card-text mb-25">
                <span class="mx-1 font-weight-bold"> شماره تلفن/نمابر : </span>
                <span dir="ltr" class=""> {{ manulaData.user.phone }}</span>
              </p>
              </div>

            </div>
          </div>
        </div>

      </div>
      <vs-divider color=""
        ></vs-divider
      >
      <div id="userTable" class="p-3">
        <b-table
          responsive
          bordered
          :items="manulaData.pay_details"
          class="mx-3 border-black"
          :fields="fields"
        >
          <template #cell(taskDescription)="data">
            <b-card-text class="font-weight-bold mb-25">
              {{ data.item.taskTitle }}
            </b-card-text>
            <b-card-text class="text-nowrap">
              {{ data.item.taskDescription }}
            </b-card-text>
          </template>
        </b-table>
      </div>

      <!-- Invoice Description: Table -->

      <!-- Invoice Description: Total -->
      <b-card-body class="pb-2 justify-center k justify-center">
        <b-card class="float-right border-black">
          <div class="mx-base">
            <table>
              <tbody>
                <tr>
                  <td class="pr-1">مبلغ (ریال):</td>
                  <td>
                    <span class="pr-1">{{ manulaData.amount }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1"> مالیات بر ارزش :</td>
                  <td>{{ manulaData.tax }}
                  </td>
                </tr>
                <tr>
                  <td class="pr-1">تخفیف :</td>
                  <td>{{ manulaData.off }}</td>
                </tr>
                <tr>
                  <td class="pr-1"> مالیات بر ارزش  :</td>
                  <td>{{ manulaData.tax_percent }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <vs-divider color="" class="my-50">
            <span class="py-1 font-size-sm font-weight-bold"
              >مبلغ پرداخت شده</span
            ></vs-divider
          >
          <div class="flex justify-center">
            <p dir="rtl" class="font-weight-bold">
              {{ manulaData.final_amount | money }} ریال
            </p>
          </div>
        </b-card>
      </b-card-body>

      <!-- Spacer -->
      <hr class="invoice-spacing text-black border-black" />

      <!-- Note -->
      <b-card-body class="invoice-padding pt-0">
        <div>
          <span class="font-weight-bold">توضیحات: </span>
          <span>{{ manulaData.description }}</span>
          <hr class="invoice-spacing text-black border-black mt-20" />
        </div>
        <div class="flex justify-between px-32">
          <p>
            <span> مهر و امضا </span>
            <br />
            <span dir="ltr"> : فروشنده</span>
          </p>
          <p class="mx-3">
            <span> مهر و امضا </span>
            <br />
            <span dir="ltr"> : خریدار</span>
          </p>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      manulaData: '',
      userId: '',
      fields: [
        { key: 'description', label: ' شرح کالا یا خدمات' },
        { key: 'num', label: 'تعداد' },
        { key: 'fi', label: 'مبلغ هر واحد' },
        { key: 'off', label: 'تخفیف' },
        { key: 'taxPrice', label: ' مالیات بر ارزش' },
        { key: 'finalAmount', label: 'جمع نهایی' }
      ]
    }
  },
  methods: {
    getManualInfo () {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      }
      // console.log(config)
      this.$http
        .get(`/invoice/manual/detail/${this.userId}`, config)
        .then((result) => {
          this.manulaData = result.data.params
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  created () {
    this.userId = this.$route.params.id
    this.getManualInfo()
  }
}
</script>

<style  lang="scss" >
@page {
  size: A4;
  color: rgb(0, 0, 0);
}
.table-bordered {
  border: 2px solid black !important;
}
.table thead th {
  border: 1px solid black !important;
}
.table-bordered td {
  border: 1px solid black !important;
}
/* DivTable.com */
.divTable {
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
}
.divTableCell,
.divTableHead {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #eee;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #eee;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}
</style>
